<template>
 <div id="Regster">
 <main class="main">
      <div class="signup">
        <form @submit.prevent="submitForm">
          <img class="logoCloudNet" src="../assets/icon-logo.png" alt="" />
          <h1 class="title-page">Register</h1>
          <p class="breif">Register Account And Start Managing Your Account</p>
          <div class="form-group">
            <input
              type="text"
              v-model="name"
              class="form-control"
              placeholder="Full Name"
            />
            <span v-if="!$v.name.required && $v.name.$dirty" class="text-danger"
              >Name is required!</span
            >
            <span v-if="!$v.name.alpha && $v.name.$dirty" class="text-danger"
              >Name is required!</span
            >
          </div>
          <div class="form-group">
            <input
              type="text"
              v-model="username"
              class="form-control"
              placeholder="UserName"
            />
            <span
              v-if="!$v.username.required && $v.username.$dirty"
              class="text-danger"
              >UserName is required!</span
            >
            <span
              v-if="!$v.username.alpha && $v.username.$dirty"
              class="text-danger"
              >UserName is required!</span
            >
          </div>

          <div class="form-group">
            <input
              type="email"
              v-model="email"
              class="form-control"
              placeholder="E-mail"
            />
            <span
              v-if="(!$v.email.required || !$v.email.email) && $v.email.$dirty"
              class="text-danger"
              >Valid Email is required!</span
            >
          </div>
          <div class="form-group">
            <input
              type="number"
              v-model="phone"
              class="form-control"
              placeholder="Phone Phone"
            />
            <span
              v-if="(!$v.phone.required || !$v.phone.email) && $v.phone.$dirty"
              class="text-danger"
              >Valid Phone is required!</span
            >
          </div>

          <div class="form-group">
            <input
              type="password"
              v-model="password"
              class="form-control"
              placeholder="Password"
            />
            <span
              v-if="!$v.password.required && $v.password.$dirty"
              class="text-danger"
              >Password is required!</span
            >
            <span
              v-if="
                (!$v.password.minLength || !$v.password.maxLength) &&
                $v.password.$dirty
              "
              class="text-danger"
              >Password must be between
              {{ $v.password.$params.minLength.min }} and
              {{ $v.password.$params.maxLength.max }} characters!</span
            >
          </div>
          <div class="form-group">
            <input
              type="password"
              v-model="password_confirmation"
              class="form-control"
              placeholder="Password Confirmation"
            />
            <span
              v-if="
                !$v.password_confirmation.required &&
                $v.password_confirmation.$dirty
              "
              class="text-danger"
              >password_confirmation is required!</span
            >
            <span
              v-if="
                (!$v.password_confirmation.minLength ||
                  !$v.password_confirmation.maxLength) &&
                $v.password_confirmation.$dirty
              "
              class="text-danger"
              >password_confirmation must be between
              {{ $v.password_confirmation.$params.minLength.min }} and
              {{ $v.password_confirmation.$params.maxLength.max }}
              characters!</span
            >
          </div>
          <input type="number" style="display: none" v-model="code" />
          <br />
          <p>LoAlready registered with us?</p>
          <p>
            <router-link class="links" to="/login">
              <strong>Sign In </strong></router-link
            >
            or
            <router-link class="links" to="/resetpassword">
              <strong>Reset Password</strong></router-link
            >
          </p>
          <!--           <p>By signing up you accept our Privacy Policy, Terms & Licensing Agreement. Protected by reCAPTCHA. Google Privacy Policy & Terms apply.</p>
 -->
          <button type="submit">Creat Account</button>
        </form>
      </div>
    </main>
 </div>
</template>

<script>
import axios from "axios";
import {
  required,
  minLength,
  maxLength,
  alpha,
  email,
} from "vuelidate/lib/validators";
export default {
  name: "Regster",
  data() {
    return {
      name: "",
      username: "",
      email: "",
      phone: "",
      password: "",
      password_confirmation: "",
      code: "14092",
    };
  },
  validations: {
    name: {
      required,
      alpha,
    },
    username: {
      required,
      alpha,
    },
    email: {
      required,
      email,
    },
    phone: {
      required,
    },
    password: {
      required,
      maxLength: maxLength(12),
      minLength: minLength(6),
    },
    password_confirmation: {
      required,
      maxLength: maxLength(12),
      minLength: minLength(6),
    },
  },
  methods: {
    async handlSubmit() {
      await axios.post("localhost:8000/api/register", {
        name: this.name,
        username: this.username,
        email: this.email,
        phone: this.phone,
        password: this.password,
        password_confirmation: this.password_confirmation,
        code: this.code,
      });

      this.$router.push("/login");
    },
    submitForm() {
      this.$v.$touch();

      /*      if (!this.$v.$invalid) {
        console.log(
          `Name: ${this.name}, Email: ${this.email}, Password: ${this.password}, Gender: ${this.gender}, Accept Terms: ${this.acceptTerms}`
        );
      } */
    },
  },
};
</script>

<style lang="scss" scoped>
#Regster {
 text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: "Jost", sans-serif;
  background: #171743;
  background: url("../assets/mcCJxI.jpeg") no-repeat center/ cover;

  .header-forms {
    margin-top: 6%;
    margin-bottom: 46px;

    h1 {
      font-size: 35px;
    }
    p {
      font-size: 16px;
    }
  }
  .main {
    width: 363.3px;
    margin: 27px;
    padding-bottom: 20px;
    background: #171743;
    overflow: hidden;
    //background: url("../../../../public/mcCJxI.jpeg") no-repeat center/ cover;
    border-radius: 10px;
    box-shadow: 5px 20px 50px #000;
    .signup {
      position: relative;
      width: 100%;
      height: 100%;
      .logoCloudNet {
        margin-top: 19px;
        width: 80px;
      }
      .title-page {
        font-size: 32px;
        color: #fff;
        margin-top: 9px;
        padding: 5px;
      }
      .breif {
        font-size: 13px;
      }
      .form-group {
        text-align: left;
        .text-danger {
          padding-left: 35px;
     }
        }
             & span{
           font-size: 14px;
          
      }
    }
    label {
      color: #e0dede;
      font-size: 2.3em;
      justify-content: center;
      display: flex;
      margin: 30px;
      font-weight: bold;
      cursor: pointer;
      transition: 0.5s ease-in-out;
    }
    input {
      width: 83%;
      height: 20px;
      background: #e0dede;
      justify-content: center;
      display: flex;
      margin: 12px auto;
      padding: 20px;
      border: 3px solid #2ec1ff;
      outline: none;
      border-radius: 5px;
    }
    button {
      width: 83%;
      height: 48px;
      margin: 10px auto;
      justify-content: center;
      display: block;
      color: #fff;
      background: #8d3de2;
      font-size: 1em;
      font-weight: bold;
      margin-top: 20px;
      outline: none;
      border: none;
      border-radius: 5px;
      transition: 0.2s ease-in;
      cursor: pointer;
    }
    p {
      color: #e0dede;
      margin: 0;
      strong {
        color: #2ec1ff;
        cursor: pointer;
      }
      .links {
        text-decoration: none;
      }
    }
    button:hover {
      background: #6d44b8;
    }
  }
}
</style>