<template>
  <div class="Regster">
   <Regster/>
  </div>
</template>

<script>
import Regster from "../components/Regster.vue";
 export default {
    components: { Regster }
}
</script>
<style lang="scss" scoped>

</style>
