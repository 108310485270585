import { render, staticRenderFns } from "./Regster.vue?vue&type=template&id=6089d076&scoped=true&"
import script from "./Regster.vue?vue&type=script&lang=js&"
export * from "./Regster.vue?vue&type=script&lang=js&"
import style0 from "./Regster.vue?vue&type=style&index=0&id=6089d076&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6089d076",
  null
  
)

export default component.exports